// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bQ_k3";
export var caseStudyBackground__lineColor = "bQ_k0";
export var caseStudyHead__imageWrapper = "bQ_mD";
export var caseStudyProjectsSection = "bQ_k4";
export var caseStudyQuote__bgRing = "bQ_kZ";
export var caseStudyQuote__bottomVideo = "bQ_lZ";
export var caseStudyQuote__light = "bQ_l7";
export var caseStudyQuote__lightLast = "bQ_mF";
export var caseStudyVideo__ring = "bQ_k2";
export var caseStudy__bgDark = "bQ_kV";
export var caseStudy__bgLight = "bQ_kT";
export var caseStudy__bgLightReverse = "bQ_l0";